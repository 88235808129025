import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleChangEditPrompt } from "../../../../store/collectionsSlice";
import MuiSelect from "../../../UI/MuiSelect";
import CsvFormatter from "./CsvFormatter";
import JsonFormatter from "./JsonFormatter";
import XmlFormatter from "./XmlFormatter";

const OutputFormat = (props) => {
  const { error, onFocus } = props;
  const editPromptsList = useSelector(
    (state) => state.collectionsSlice.editPromptsList
  );
  const modelsVersionsList = useSelector(
    (state) => state.collectionsSlice.modelsVersionsList
  );
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const [activeScreen, setActiveScreen] = useState("Preview");
  const [selectedLanguage, setSelectedLanguage] = useState([
    { label: "Afrikaans", value: "Afrikaans" },
    { label: "Arabic", value: "Arabic" },
    { label: "Armenian", value: "Armenian" },
    { label: "Azerbaijani", value: "Azerbaijani" },
    { label: "Belarusian", value: "Belarusian" },
    { label: "Bosnian", value: "Bosnian" },
    { label: "Bulgarian", value: "Bulgarian" },
    { label: "Catalan", value: "Catalan" },
    { label: "Chinese", value: "Chinese" },
    { label: "Croatian", value: "Croatian" },
    { label: "Czech", value: "Czech" },
    { label: "Danish", value: "Danish" },
    { label: "Dutch", value: "Dutch" },
    { label: "English", value: "English" },
    { label: "Estonian", value: "Estonian" },
    { label: "Finnish", value: "Finnish" },
    { label: "French", value: "French" },
    { label: "Galician", value: "Galician" },
    { label: "German", value: "German" },
    { label: "Greek", value: "Greek" },
    { label: "Hebrew", value: "Hebrew" },
    { label: "Hindi", value: "Hindi" },
    { label: "Hungarian", value: "Hungarian" },
    { label: "Icelandic", value: "Icelandic" },
    { label: "Indonesian", value: "Indonesian" },
    { label: "Italian", value: "Italian" },
    { label: "Japanese", value: "Japanese" },
    { label: "Kannada", value: "Kannada" },
    { label: "Kazakh", value: "Kazakh" },
    { label: "Korean", value: "Korean" },
    { label: "Latvian", value: "Latvian" },
    { label: "Lithuanian", value: "Lithuanian" },
    { label: "Macedonian", value: "Macedonian" },
    { label: "Malay", value: "Malay" },
    { label: "Marathi", value: "Marathi" },
    { label: "Maori", value: "Maori" },
    { label: "Nepali", value: "Nepali" },
    { label: "Norwegian", value: "Norwegian" },
    { label: "Persian", value: "Persian" },
    { label: "Polish", value: "Polish" },
    { label: "Portuguese", value: "Portuguese" },
    { label: "Romanian", value: "Romanian" },
    { label: "Russian", value: "Russian" },
    { label: "Serbian", value: "Serbian" },
    { label: "Slovak", value: "Slovak" },
    { label: "Slovenian", value: "Slovenian" },
    { label: "Spanish", value: "Spanish" },
    { label: "Swahili", value: "Swahili" },
    { label: "Swedish", value: "Swedish" },
    { label: "Tagalog", value: "Tagalog" },
    { label: "Tamil", value: "Tamil" },
    { label: "Thai", value: "Thai" },
    { label: "Turkish", value: "Turkish" },
    { label: "Ukrainian", value: "Ukrainian" },
    { label: "Urdu", value: "Urdu" },
    { label: "Vietnamese", value: "Vietnamese" },
    { label: "Welsh", value: "Welsh" },
    { label: "Wu", value: "Wu" },
  ]);
  const [menuOptions, setMenuOptions] = useState([
    { label: "JSON", value: "json" },
    { label: "XML", value: "xml" },
  ]);
  // const languageOptions = [

  // ];

  const isImageTypeModel =
    modelsVersionsList?.filter((i) => i?.id === promptData?.llmVersion)[0]
      ?.model_type === "Image";
  const dispatch = useDispatch();

  const handleChangeOutputFormat = (event) => {
    let data = {
      ...promptData,
      outputFormat: event.target.value,
      promptOutput: "",
    };
    dispatch(handleChangEditPrompt(data));
    setActiveScreen("Preview");
  };

  const handleChangeLanguageFormat = (event) => {
    let data = {
      ...promptData,
      output_language: event.target.value,
      promptOutput: "",
    };
    dispatch(handleChangEditPrompt(data));
    setActiveScreen("Preview");
  };
  const handleExpand = () => {
    let data1 = {
      ...promptData,
      expandFormatter: !promptData?.expandFormatter,
    };
    dispatch(handleChangEditPrompt(data1));
  };

  useEffect(() => {
    if (isImageTypeModel) {
      setMenuOptions([{ label: "JSON", value: "json" }]);
      let data = {
        ...promptData,
        outputFormat: "json",
      };
      dispatch(handleChangEditPrompt(data));
    } else {
      setMenuOptions([
        { label: "JSON", value: "json" },
        { label: "CSV", value: "csv" },
        { label: "XML", value: "xml" },
      ]);
    }
  }, [isImageTypeModel]);

  const formatters = {
    json: <JsonFormatter activeScreen={activeScreen} />,
    csv: <CsvFormatter activeScreen={activeScreen} />,
    xml: <XmlFormatter activeScreen={activeScreen} />,
  };

  return (
    <div>
      <div
        className={`flex items-center justify-between p-2 bg-tertiary  ${
          promptData?.expandFormatter && !!promptData?.outputFormat
            ? "rounded-t-lg border-b-1 border-primary "
            : "rounded-lg border-none"
        }`}
      >
        <div className="flex items-center  gap-1 ">
          <p className="text-sm text-primary">Response Format</p>
          <div className="w-36">
            <MuiSelect
              value={promptData?.outputFormat}
              onChange={handleChangeOutputFormat}
              error={error}
              onFocus={onFocus}
              menuItems={menuOptions}
            />
          </div>
        </div>
        <div className="flex items-center  gap-1 ml-2 mr-2 ">
          <p className="text-sm text-primary">Response language</p>
          <div className="w-36">
            <MuiSelect
              value={promptData?.output_language}
              onChange={(e) => handleChangeLanguageFormat(e)}
              error={error}
              onFocus={onFocus}
              menuItems={selectedLanguage}
            />
          </div>
        </div>
        <div className="flex items-center gap-4">
          {promptData?.outputFormat && promptData?.expandFormatter && (
            <Button
              startIcon={<VisibilityOutlined />}
              variant={"text"}
              color={"secondary"}
              onClick={() =>
                setActiveScreen((ini) =>
                  ini === "Preview" ? promptData?.outputFormat : "Preview"
                )
              }
              sx={{ textTransform: "capitalize" }}
            >
              {activeScreen}
            </Button>
          )}
          {promptData?.outputFormat && (
            <Button
              variant={"text"}
              color={"secondary"}
              onClick={handleExpand}
              sx={{ textTransform: "capitalize" }}
              endIcon={
                promptData?.expandFormatter ? (
                  <KeyboardArrowUp className="text-grayEditor400" />
                ) : (
                  <KeyboardArrowDown className="text-grayEditor400" />
                )
              }
            >
              {promptData?.expandFormatter ? "Collapse" : "Expand"}
            </Button>
          )}
        </div>
      </div>
      <div className="bg-secondary rounded-b-lg">
        {promptData?.expandFormatter && formatters[promptData?.outputFormat]}
      </div>
    </div>
  );
};

export default OutputFormat;
