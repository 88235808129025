import {
  Box,
  FormControlLabel,
  FormLabel,
  Slider,
  Stack,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllContexts } from "../../../../services/app/api";
import { MODEL_MAX_TOKEN_COUNT } from "../../../../services/constants";
import { handleChangEditPrompt } from "../../../../store/collectionsSlice";
import { useAuth } from "../../../Auth/AuthContext";
import { useThemeContext } from "../../../Themes/ThemeContextProvider";
import MuiSelect from "../../../UI/MuiSelect";

const PromptSettings = () => {
  const dispatch = useDispatch();
  const { colors } = useThemeContext();
  const editPromptsList = useSelector(
    (state) => state.collectionsSlice.editPromptsList
  );
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const { activeTeam } = useAuth();
  const [contextData, setContextData] = useState([]);

  const handleProgressChange = (e, v) => {
    let data = {
      ...promptData,
      prompt_setting: {
        ...promptData?.prompt_setting,
        [e.target?.name]: v,
      },
    };
    dispatch(handleChangEditPrompt(data));
  };
  const handleVariationChange = (e, v) => {
    let data = {
      ...promptData,
      variationCount: v,
    };
    dispatch(handleChangEditPrompt(data));
  };

  useEffect(() => {
    let params = {
      page: 1,
      page_size: 500,
    };
    getAllContexts({ team_id: activeTeam, params }).then((res) =>
      setContextData(res.items)
    );
  }, []);

  return (
    <div className="w-full">
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={2}
        justifyContent={"space-between"}
      >
        <FormLabel
          htmlFor="temperature"
          sx={{ color: colors.textTertiary, fontSize: 14 }}
        >
          Context:
        </FormLabel>
        <div className="w-[70%]">
          <MuiSelect
            selectClassName="bg-primary"
            menuItems={contextData}
            showSelect={true}
            value={promptData?.context}
            onChange={(e) => {
              let data = {
                ...promptData,
                context: e.target.value,
              };
              dispatch(handleChangEditPrompt(data));
            }}
            valueKey="id"
            labelKey="name"
          />
        </div>
      </Box>
      <Stack
        gap={1}
        display={promptData?.model_type === "Text" ? "block" : "none"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          gap={2}
          justifyContent={"space-between"}
        >
          <FormLabel
            htmlFor="temperature"
            sx={{ color: colors.textTertiary, fontSize: 14 }}
          >
            Temperature:
          </FormLabel>
          <div className="w-[70%]">
            <Slider
              defaultValue={0.5}
              step={0.1}
              max={1}
              min={0}
              aria-label="Temperature"
              name="temperature"
              valueLabelDisplay="auto"
              onChange={handleProgressChange}
              value={promptData?.prompt_setting?.temperature}
            />
          </div>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          gap={2}
          justifyContent={"space-between"}
        >
          <FormLabel
            htmlFor="variation_count"
            sx={{ color: colors.textTertiary, fontSize: 14 }}
          >
            Variation:
          </FormLabel>
          <div className="w-[70%]">
            <Slider
              defaultValue={1}
              step={1}
              max={promptData?.llmModelName !== "anthropic" ? 4 : 1}
              min={1}
              aria-label="response"
              name="variation_count"
              valueLabelDisplay="auto"
              value={promptData?.variationCount}
              onChange={handleVariationChange}
            />
          </div>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          gap={2}
          justifyContent={"space-between"}
        >
          <FormLabel
            htmlFor="token"
            sx={{ color: colors.textTertiary, fontSize: 14 }}
          >
            Maximum tokens to use:
          </FormLabel>
          <div className="w-[70%]">
            <Slider
              defaultValue={3000}
              step={1}
              max={MODEL_MAX_TOKEN_COUNT[promptData?.llmModelName] || 10000}
              min={1}
              aria-label="token"
              name="max_token"
              valueLabelDisplay="auto"
              value={promptData?.prompt_setting?.max_token}
              onChange={handleProgressChange}
            />
          </div>
        </Box>
      </Stack>
      <div className="flex justify-end items-center gap-2">
        <FormControlLabel
          control={
            <Switch
              checked={promptData?.cache_setting?.active}
              onChange={(e) => {
                let data = {
                  ...promptData,
                  cache_setting: {
                    ...promptData?.cache_setting,
                    active: e.target.checked,
                  },
                };
                dispatch(handleChangEditPrompt(data));
              }}
            />
          }
          label={<span className="text-sm">Use cache</span>}
          sx={{ fontSize: 14, color: colors.textTertiary }}
        />
        <div className="w-80 items-center gap-4 flex justify-end">
          <FormLabel sx={{ color: colors.textTertiary, fontSize: 14 }}>
            Refresh cache every:
          </FormLabel>
          <div className="w-32">
            <MuiSelect
              value={promptData?.cache_setting?.interval}
              selectClassName="bg-primary"
              menuItems={[
                { value: "day", label: "Day" },
                { value: "week", label: "Week" },
                { value: "month", label: "Month" },
              ]}
              onChange={(e) => {
                let data = {
                  ...promptData,
                  cache_setting: {
                    ...promptData?.cache_setting,
                    interval: e.target.value,
                  },
                };
                dispatch(handleChangEditPrompt(data));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromptSettings;
